import React from 'react';
import PropTypes from "prop-types"
import moment from "moment-timezone";
import {concat, mapForSelect, roundDecimal, select, sortAssetsLabels} from "../../../common/commonHandlers";
import ResourceComponent from "../../../components/ResourceComponent";
import {
    Button,
    Col,
    ControlLabel,
    Form,
    FormControl,
    FormGroup,
    Glyphicon,
    OverlayTrigger,
    Row,
    Table,
    Tooltip
} from "react-bootstrap";
import Select from "react-select";
import {Link} from "react-router";
import PlantName from "../../../components/PlantName";
import BundleServices from "../../BundleServices/BundleServices";
import InputGroup from "react-bootstrap/lib/InputGroup";
import InputGroupAddon from "react-bootstrap/lib/InputGroupAddon";
import {throttle} from "throttle-debounce";

class ProposalServiceForm extends ResourceComponent {

    state = {
        resource:
            {
                asset_ids: [],
                scheduler_events: [],
                ave_salary_rate: 0.0,
                man_hours: 1,
                service_total: 0.0
            }
    };

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.setState({resource: {...this.state.resource, ...this.props.ps}},()=>{
            let {ave_salary_rate, service_total} = this.state.resource
            ave_salary_rate = parseFloat(ave_salary_rate.toString().replace(/[^0-9.]/g,''))
            service_total = parseFloat(service_total.toString().replace(/[^0-9.]/g,''))
            this.setState({resource: {...this.state.resource, ave_salary_rate, service_total}})
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.submit(this.submit);
    }

    recalculate = (name, func) => () => {
        const {resource} = this.state;
        if (name === 'service_total') {
            const totalF = resource.service_total ?
                parseFloat(resource.service_total.toString()
                    .replace(/[^0-9.]/g,'')
                    .replace(',', '')) : 0;
            resource.service_total = totalF
            resource.ave_salary_rate = totalF / resource.man_hours;
        } else {
            const avgSalaryF = resource.ave_salary_rate ?
                parseFloat(resource.ave_salary_rate.toString()
                    .replace(/[^0-9.]/g, '')) : 0;

            resource.service_total = parseFloat(
                roundDecimal(resource.man_hours * avgSalaryF)
                    .toString()
                    .replace(/[^0-9.]/g, '')
            );
            resource.ave_salary_rate = avgSalaryF;
        }
        this.setState({resource}, func);
    };

    selectedService = () => {
        const {resource} = this.state;
        const {services} = this.props;
        return services.find(s => s.id === resource.service_id);
    };

    updatePrices = () => {
        const service = this.selectedService();
        if (service) {
            const {resource} = this.state;
            resource.man_hours = service.estimated_total_time || resource.man_hours;
            resource.ave_salary_rate = service.rate || resource.ave_salary_rate;
            this.setState({resource}, this.recalculate());
        }
    };

    updateEquipment = () => {
        const service = this.selectedService();
        if (service) {
            const {equipments} = this.props;
            const {resource} = this.state;
            resource.equipment_ids = equipments.filter(e => service.equipments.includes(e.id)).map(e => e.id);
            this.setState({resource}, this.recalculate());
        }
    };

    updateDescription = () => {
        const service = this.selectedService();
        if (service) {
            const {resource} = this.state;
            resource.notes = service.skills || "";
            this.setState({resource});
        }
    };

    serviceEquipmentOptions = () => {
        const {equipments} = this.props;
        const service = this.selectedService();
        let serviceEquipments = [];
        if (service) {
            serviceEquipments = equipments.filter(e => service.equipments.includes(e.id));
        }
        const restEquipment = equipments.filter(e => !serviceEquipments.some(se => se.id === e.id));
        return [
            {label: 'Service equipment', options: mapForSelect(serviceEquipments.filter(p => !p.disabled))},
            {label: 'All equipment', options: mapForSelect(restEquipment.filter(p => !p.disabled))}
        ];
    };

    submit = () => {
        const {resource} = this.state;
        this.props.onSave(resource)
    };

    onDeleteBS = (resource) => {
        throttle(1500, this.props.onDeleteBS(resource))
    }

    duplicate = () => {
        const {resource} = this.state;
        this.recalculate("service_total", () => this.props.onDuplicate(resource))();
    }

    render() {
        const {resource} = this.state;
        const {priorities, services, equipments, compareValues, ps} = this.props;
        const isBS = ps.is_bundle_service;
        const servicesOptions = mapForSelect(services.filter(s => !s.disabled));
        const servicesOptionsAll = mapForSelect(services);
        const prioritiesOptions = mapForSelect(priorities.filter(p => !p.disabled));
        const prioritiesOptionsAll = mapForSelect(priorities);
        const groupedEquipment = this.serviceEquipmentOptions();
        const allEquipment = mapForSelect(equipments);
        const proposalAssets = resource?.asset_ids?.map(id => this.props.getAllAssets().find(x => x.id === id)).sort((a, b) => sortAssetsLabels(a, b, 'label'))

        return (
            <div>
                <Form>
                    <Row>
                        {!isBS &&
                            <Col xs={12}>
                                <FormGroup bsSize="small">
                                    <ControlLabel>Service</ControlLabel>
                                    <br/>
                                    <Select className="Select"
                                            classNamePrefix="select"
                                            value={select(servicesOptionsAll, resource.service_id)}
                                            options={servicesOptions}
                                            onChange={this.selectResourceAttr('service_id', () => {
                                                this.updatePrices();
                                                this.updateEquipment();
                                                this.updateDescription();
                                                compareValues(resource.service_id, ps.service_id, ps.service_status_name, ps.id)
                                            })}
                                    />
                                </FormGroup>
                            </Col>}
                        <Col xs={12}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Priority</ControlLabel>
                                <br/>
                                <Select
                                    isMulti={true}
                                    className="Select"
                                    classNamePrefix="select"
                                    value={select(prioritiesOptionsAll, resource.priority_ids)}
                                    options={prioritiesOptions}
                                    onChange={this.selectResourceAttr('priority_ids')}
                                    placeholder="Select Priority"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {!isBS &&
                        <Row>
                            <Col xs={12} className="text-right">
                                <Button
                                    onClick={() => {
                                        const newAssets = this.props.getAllAssets()
                                            .filter(a => this.selectedService().plants.includes(a.plant_id))
                                            .map(a => a.id);
                                        resource.asset_ids = concat(resource.asset_ids, newAssets);
                                        this.setState({resource});
                                    }}
                                    disabled={!resource.service_id}
                                    bsSize="xsmall"
                                    className="small-margin"
                                >
                                    Add Qualifying Assets
                                </Button>
                                <Button
                                    onClick={() => {
                                        const newAssets = this.props.getSelectedAssets()
                                            .map(a => a.id);
                                        resource.asset_ids = concat(resource.asset_ids, newAssets);
                                        this.setState({resource});
                                    }}
                                    bsSize="xsmall"
                                >
                                    Add Selected Assets
                                </Button>
                            </Col>
                        </Row>}
                    <Row className="top10">
                        <Col md={4}>
                            {resource.invoice_id &&
                                <Link
                                    to={`/proposals/${resource.proposal_id}/invoices`}>
                                    Invoice No: {resource.invoice_no}
                                </Link>
                            }
                        </Col>
                        <Col md={4}>
                            {resource.work_order_id &&
                                <Link to={`/work_orders/${resource.proposal_id}`}>
                                    {`WO #${resource.work_order_no}`}
                                </Link>
                            }
                        </Col>
                        <Col md={4}>
                            {resource.scheduler_events.map((event, i) => (
                                <div key={i} className="text-danger">
                                    <strong>Scheduled: {moment(event.event_start).format("MMM D, YYYY")}</strong>
                                </div>
                            ))
                            }
                        </Col>
                    </Row>
                    {!isBS &&
                        <Row>
                            <Col xs={12}>
                                <Table striped condensed hover className="top10">
                                    <tbody>
                                    {resource.asset_ids.map((id, i) => {
                                            const ass = proposalAssets[i]
                                            return <tr key={ass?.id}>
                                                <td onClick={() => ass && this.props.onAssetClick(ass)}>
                                                    {ass?.label}{" "}{<PlantName plant={ass?.plant_name}/>}{" "}
                                                    {ass?.plant_count > 1 ? `[${ass?.plant_count}]` : null}{" "}
                                                </td>
                                                <td>{ass?.plant_dbh ? `DBH: ${ass?.plant_dbh}` : ''}</td>
                                                <td>
                                                    <Link
                                                        onClick={() => {
                                                            resource.asset_ids = resource.asset_ids.filter(x => x !== proposalAssets[i].id);
                                                            this.setState({resource});
                                                        }}>
                                                    <span className="text-danger pointer">
                                                        <Glyphicon glyph="remove-sign"/>
                                                    </span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        }
                                    )
                                    }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>}

                    <Row>
                        <Col xs={12}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Proposal Service Notes</ControlLabel>
                                <FormControl
                                    componentClass="textarea"
                                    rows={3}
                                    name="notes"
                                    placeholder="Proposal Service Notes"
                                    value={resource.notes}
                                    onChange={this.updateResourceAttr}
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Work Order Notes</ControlLabel>
                                <FormControl
                                    componentClass="textarea"
                                    rows={3}
                                    name="wo_notes"
                                    placeholder="Work Order Notes"
                                    value={resource.wo_notes}
                                    onChange={this.updateResourceAttr}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col xs={4}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Man Hours</ControlLabel>
                                <FormControl
                                    placeholder="Man Hours"
                                    name="man_hours"
                                    min={1}
                                    type="number"
                                    value={resource.man_hours}
                                    onBlur={ e => {(!e.target.value || e.target.value === 0) && this.updateResourceAttr({target: {name: "man_hours", value: 1}},
                                        this.recalculate('man_hours', () => {
                                        compareValues(parseFloat(resource.man_hours), parseFloat(ps.man_hours), ps.service_status_name, ps.id)
                                    }))}}
                                    onChange={e => this.updateResourceAttr(e, this.recalculate('man_hours', () => {
                                        compareValues(parseFloat(resource.man_hours), parseFloat(ps.man_hours), ps.service_status_name, ps.id)
                                    }))}
                                />
                            </FormGroup>
                        </Col>

                        <Col xs={4}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Rate</ControlLabel>
                                <InputGroup>
                                    <InputGroupAddon>
                                        $
                                    </InputGroupAddon>
                                <FormControl
                                    placeholder="Rate"
                                    name="ave_salary_rate"
                                    type="text"
                                    value={resource.ave_salary_rate.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                    onBlur={e => this.dollarFormatAttr(e, this.recalculate('ave_salary_rate', () => compareValues(parseFloat(resource.ave_salary_rate), parseFloat(ps.ave_salary_rate), ps.service_status_name, ps.id)))}
                                    onChange={e => this.updateResourceAttr(e)}
                                />
                                </InputGroup>
                            </FormGroup>
                        </Col>

                        <Col xs={4}>
                            <FormGroup bsSize="small">
                                <ControlLabel>Service Total</ControlLabel>
                                <InputGroup>
                                    <InputGroupAddon>
                                        $
                                    </InputGroupAddon>
                                <FormControl
                                    placeholder="Service Total"
                                    name="service_total"
                                    type="text"
                                    value={resource.service_total.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                    onBlur={e => this.dollarFormatAttr(e, this.recalculate('service_total', () => compareValues(parseFloat(resource.ave_salary_rate), parseFloat(ps.ave_salary_rate), ps.service_status_name, ps.id)))}
                                    onChange={e => this.updateResourceAttr(e)}
                                />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <hr/>
                    {!isBS &&
                        <>
                            <Row>
                                <Col xs={12}>
                                    <FormGroup bsSize="small">
                                        <ControlLabel>Optional Equipment</ControlLabel>
                                        <Select className="Select" classNamePrefix="select"
                                                value={select(allEquipment, resource.equipment_ids)}
                                                options={groupedEquipment}
                                                isMulti
                                                onChange={this.selectResourceAttr('equipment_ids')}
                                                placeholder="Select Equipment"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr/>
                        </>}

                    <Row>
                        <Col sm={5} className="text-left">
                            <Button
                                bsStyle="danger"
                                className={`${isBS && 'mr-9 marginBottom8'}`}
                                disabled={resource.deleteable === false || !resource.id}
                                onClick={() => this.props.onDelete(resource)}
                                bsSize="xsmall"
                            >
                                {resource.deleteable === false ? "Not Deletable" : (isBS ? "Delete All Services in Bundle" : "Delete")}
                            </Button>
                            {isBS && resource.deleteable &&
                                <Button
                                    bsStyle="danger"
                                    className='marginBottom8'
                                    disabled={resource.deleteable === false}
                                    onClick={() => this.onDeleteBS(resource.id)}
                                    bsSize="xsmall"
                                >
                                    Remove All Services from Bundle
                                </Button>}
                        </Col>
                        <Col sm={7} className="text-right">
                            {resource?.site_moved ?
                                <OverlayTrigger placement="top" overlay={<Tooltip>Site was moved!</Tooltip>}>
                                    <span>
                                        <Button
                                            className="marginBottom8"
                                            bsStyle="success"
                                            bsSize="xsmall"
                                            disabled
                                            style={{ pointerEvents: 'none' }}
                                        >
                                            Duplicate
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                                :
                                <Button
                                    className="marginBottom8"
                                    bsStyle="success"
                                    type="button"
                                    bsSize="xsmall"
                                    onClick={e => {
                                        this.duplicate()
                                    }}
                                >
                                    Duplicate
                                </Button>
                            }
                            <Button
                                className="ml-9 marginBottom8"
                                type="submit"
                                bsStyle="success"
                                bsSize="xsmall"
                                onClick={e => {
                                    e.preventDefault();
                                    this.submit();
                                }}
                            >
                                Save
                            </Button>
                            {resource?.site_moved ?
                                <OverlayTrigger placement="top" overlay={<Tooltip>Site was moved!</Tooltip>}>
                                    <span>
                                        <Button
                                            className='ml-9 marginBottom8'
                                            bsStyle="success"
                                            bsSize="xsmall"
                                            disabled
                                            style={{pointerEvents: 'none'}}
                                        >
                                            Save & New
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                                :
                                <Button
                                    className='ml-9 marginBottom8'
                                    bsStyle="success"
                                    bsSize="xsmall"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.props.onSaveAndNew(resource)
                                    }}
                                >
                                    Save & New
                                </Button>
                            }
                        </Col>
                    </Row>
                </Form>
                {isBS &&
                            <BundleServices bundleServices={ps.bundle_services}
                                            view='MapViewPage'
                                            isDeletable={resource.deleteable}
                                            onDelete={(service_id) =>
                                                this.props.onDeleteServiceFromBS(resource.id, service_id)}
                                            getAllAssets={this.props.getAllAssets}
                                            allEquipment={allEquipment}
                                            draggable={true}
                                            resource={resource}
                                            onSaveBSOrder={this.props.onSaveBSOrder}
                            />
                }

            </div>
        );
    }
}

ProposalServiceForm.propTypes = {
    ps: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    onSaveBSOrder: PropTypes.func,
    onSaveAndNew: PropTypes.func,
    onDelete: PropTypes.func,
    onDeleteBS: PropTypes.func,
    onAssetClick: PropTypes.func,
    getSelectedAssets: PropTypes.func,
    getAllAssets: PropTypes.func,
    submit: PropTypes.func,
    services: PropTypes.array.isRequired,
    priorities: PropTypes.array.isRequired,
    equipments: PropTypes.array.isRequired
};

ProposalServiceForm.defaultProps = {
    onDelete: () => {
    },
    onDeleteBS: () => {
    },
    onSave: () => {
    },
    onSaveBSOrder: () => {
    },
    onSaveAndNew: () => {
    },
    onAssetClick: () => {
    },
    submit: () => {
    },
    services: [],
    priorites: [],
    equipments: [],
};

export default ProposalServiceForm;