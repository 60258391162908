import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, Grid, Row} from "react-bootstrap";
import moment from "moment/moment";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";
import ResourceComponent from "../../components/ResourceComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import createApiService from "../../common/clientActionsBuilder";
import HolidayModal from "./HolidayModal";
import FetchHolidaysModal from "./FetchHolidaysModal";
import './Holidays.css';
import {addAlert} from "../App/actions"

import {
    uploadImages,
    uploadFiles,
    fetchHolidayImages,
    removeHolidayImage,
    rotateImage,
    fetchHolidayFiles,
    removeHolidayFile
} from './FetchHolidaysModalApi'
import {debounce} from "throttle-debounce";
import {defaultDateFormatShort} from "../../common/commonHandlers";

const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;

const holidayAPI = createApiService('holidays', 'holiday', 'Holidays')

const api = {
    ...holidayAPI,
    uploadImages,
    uploadFiles,
    fetchHolidayImages,
    fetchHolidayFiles,
    removeHolidayImage,
    removeHolidayFile,
    rotateImage,
    addAlert
};

class Holidays extends ResourceComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            holidays: [],
            holiday: {
                recurring: null,
                name: '',
                date: null
            },
            resource: {
                sort: 'ASC',
                sort_by: 'date',
            },
            showModal: false,
            showFetchModal: false,
            images: [],
            docs: []
        };

        this.delayedSearch = debounce(500, this.reload);
    }

    componentDidMount() {
        this.reload();
    }

    dateFormatter = (cell, row) => {
        const dateDiff = moment(row.date).startOf('day').diff(moment().startOf('day'))

        if (dateDiff >= 0) {
            return (
                <div>
                    <div className={'word-break'}>{moment(row.date).format(defaultDateFormatShort)}
                        {dateDiff === 0 ? ` (today)` : ` (in ${moment(row.date).startOf('day').diff(moment().startOf('day'), 'days')} days)`}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={'word-break'}>{moment(row.date).format(defaultDateFormatShort)}</div>
            )
        }
    }

    indexN = (cell, row, enumObject, index) => {
        return (<div>{index + 1}</div>)
    };

    rowFormatter = (cell, row, enumObject, index) => {
        return <div>
            <Row>
                <Col xs={6}>#</Col>
                <Col xs={6}>{this.indexN(cell, row, enumObject, index)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Name</Col>
                <Col xs={6}>{row.name}</Col>
            </Row>
            <Row>
                <Col xs={6}>Date</Col>
                <Col xs={6}>{this.dateFormatter(cell, row)}</Col>
            </Row>
            <Row>
                <Col xs={6}>Options</Col>
                <Col xs={6}>
                    <div>
                    <span onClick={() => {
                        this.props.actions.get(row.id, holiday => this.setState({holiday: holiday}, () => {
                            this.fetchImages()
                            this.fetchFiles()
                            this.setState({showModal: true})
                        }))
                    }} className="pointer">
                        <FontAwesomeIcon icon="edit" className="bigger"/>
                    </span>
                    </div>
                </Col>
            </Row>
        </div>
    };

    reload = () => {
        const {resource} = this.state;
        this.props.actions.load(resource, results => {
            this.setState({holidays: results.content});
        });
    };

    delete = () => {
        const {holiday} = this.state;
        this.props.actions.remove(holiday, () => {
            this.setState({showModal: false, holiday: null})
            this.reload()
        })
    }

    save = () => {
        const {holiday} = this.state;
        const isValid = this.validateForm(holiday)
        if (!isValid) {
            this.props.actions.addAlert({
                message: "Fill all the fields",
                mood: "danger",
                dismissAfter: 2500,
            })
            return false
        }
        this.props.actions.save(holiday, () => {
            this.setState({showModal: false, holiday: null})
            this.reload()
        })
    }

    validateForm = (holiday) => {

        let requiredFields = [
            "name",
            "date"
        ]

        if (!holiday) return false

        const invalidFields = requiredFields.some(prop => {
            if (
                holiday[prop] === null ||
                holiday[prop] === undefined ||
                holiday[prop].toString().trim().length < 1
            ) {
                return true
            } else {
                return false
            }
        })

        if (invalidFields) {
            return false
        }

        return true
    }

    updateEventType = (e) => {
        const {holiday} = this.state
        const {id} = e.target
        const isRecurring = id === 'recurring' ? true : false
        this.setState({
            holiday: {
                ...holiday,
                recurring: isRecurring,
                date: !holiday?.date ? moment() : holiday?.date
            }
        })
    }

    showModal = () => {
        this.setState({showModal: true});
    };

    closeModal = () => {
        this.setState({
            showModal: false,
            holiday: null,
            images: [],
            docs: [],
            calendarDate: null
        }, this.reload);
    };

    closeFetchModal = () => {
        this.setState({showFetchModal: false}, this.reload);
    };

    updateName = (data) => {
        let {holiday} = this.state;
        this.setState({
            holiday: {
                ...holiday,
                name: data.target.value
            }
        });
    }

    updateDate = (data) => {
        const {holiday} = this.state

        this.setState({
            holiday: {
                ...holiday,
                date: moment.utc(data).add(1, 'hour')
            }
        })
    }

    uploadFile = (files, callback) => {
        const {holiday, images, docs} = this.state;
        const {uploadImages, uploadFiles} = this.props.actions;
        const imageAttachment = files && files[0] && files[0].type.indexOf("image") > -1

        if (imageAttachment) {
            uploadImages(files, holiday.id, data => {
                this.setState({images: [...images, data]}, this.fetchImages);
                callback && callback()
            })
        } else {
            uploadFiles(files, holiday.id, data => {
                this.setState({docs: [...docs, data]}, this.fetchFiles);
                callback && callback()
            })
        }
    }

    fetchImages = () => {
        const {id} = this.state.holiday
        const {fetchHolidayImages} = this.props.actions;
        fetchHolidayImages(id, images => this.setState({images}));
    }

    fetchFiles = () => {
        const {id} = this.state.holiday;
        const {fetchHolidayFiles} = this.props.actions;
        fetchHolidayFiles(id, docs => this.setState({docs: docs.content}))
    }

    removeImage = (id) => {
        const {holiday} = this.state;
        const {get, removeHolidayImage} = this.props.actions;
        removeHolidayImage(id, () => {
            get(holiday.id, holiday => this.setState(holiday, () => {
                this.fetchImages()
                this.setState({showModal: true})
            }))
        })
    }

    removeFile = (id) => {
        const {holiday} = this.state;
        const {get, removeHolidayFile} = this.props.actions;
        removeHolidayFile(id, () => {
            get(holiday.id, holiday => this.setState(holiday, () => {
                this.fetchFiles()
                this.setState({showModal: true})
            }))
        })
    }

    rotateHolidayImage = (id, direction) => {
        const {holiday} = this.state;
        this.props.actions.rotateImage(id, direction, () => {
            this.props.actions.get(holiday.id, holiday => this.setState(holiday, () => {
                this.fetchImages()
                this.setState({showModal: true})
            }))
        })
    }

    rowStyleFormat = (cell) => {
        if (cell && cell.date && moment(cell.date).startOf('day').diff(moment().startOf('day'), 'days') < 0) {
            return {backgroundColor: 'rgba(211, 217, 227, 0.4)'}
        }
    };

    toggleRemovalModal = () => {
        const {isRemovalModalOpened} = this.state;
        this.setState({isRemovalModalOpened: !isRemovalModalOpened})
    }

    render() {
        let {holidays, resource, images, docs, showModal} = this.state;

        let currentHolidays = []
        let pastHolidays = []

        if (holidays && holidays.length > 0) {
            holidays.map(holiday => {
                holiday.duration = moment(holiday.date).startOf('day').diff(moment().startOf('day'), 'days') >= 0

                if (!holiday.duration) {
                    pastHolidays.push(holiday)
                } else {
                    currentHolidays.push(holiday)
                }
            })
            holidays = currentHolidays.concat(pastHolidays.sort((a, b) => new Date(b.date) - new Date(a.date)))
        }

        return (
            <Grid fluid id='holidays'>
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
                <Row className="vcenter">
                    <Col md={4} className={isMobile ? "vcenter full-width" : "vcenter"}>
                        <h2 className="no-top-margin mr-9">Holidays</h2>
                        <h5 className={smMobile ? "text-primary nmt-10" : "text-primary"}>{holidays?.length} returned</h5>
                    </Col>
                    <Col md={8} className={isMobile ? "text-right full-width" : "text-right"}>
                        <Button bsStyle="primary" className="no-top-margin small-margin"
                                onClick={() => this.setState({showModal: true, images: [], docs: []})}>New {" "}
                            <FontAwesomeIcon
                                icon="plus" className="small"/></Button>
                        <Button bsStyle="info" className="no-top-margin small-margin"
                                onClick={() => this.setState({showFetchModal: true})}>Fetch {" "} <FontAwesomeIcon
                            icon="plus" className="small"/></Button>
                    </Col>
                </Row>
                <hr className='mt-0 bottom0'/>
                {isMobile ?
                    <Row>
                        <Row className="padding10 ml-15">
                        </Row>
                        <Col md={12} className="remaining vertical-responsive-height">
                            <BootstrapTable
                                ref="table"
                                data={holidays}
                                striped={true}
                                bordered={false}
                                trStyle={this.rowStyleFormat}
                                hover={true}
                                remote
                                className="wrapped"
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    hidden
                                    isKey
                                />
                                <TableHeaderColumn
                                    dataField="id"
                                    dataFormat={this.rowFormatter}
                                >
                                </TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                    </Row>
                    :
                    <Row style={{height: 0}}>
                        <BootstrapTable
                            ref="table"
                            data={holidays}
                            striped={true}
                            bordered={false}
                            hover={true}
                            trStyle={this.rowStyleFormat}
                            remote
                            className="wrapped"
                            options={
                                {
                                    sortName: resource.sort_by,
                                    sortOrder: resource.sort,
                                    onSortChange: (sort_by, sort) => {
                                        let {resource} = this.state;
                                        resource = {...resource, sort_by, sort};
                                        this.setState({resource}, this.delayedSearch);
                                    }
                                }
                            }
                        >
                            <TableHeaderColumn
                                dataField="id"
                                hidden
                                isKey
                            />
                            <TableHeaderColumn
                                dataField="any"
                                dataFormat={this.indexN}
                                width={"5%"}
                            >
                                #
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="name"
                                width={"25%"}
                            >
                                Name
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="date"
                                dataSort
                                width={"20%"}
                                dataFormat={this.dateFormatter}
                            >
                                Date
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                width={"15%"}
                                dataFormat={(cell, row) => {
                                    return <div>
                                        <span onClick={() => {
                                            this.props.actions.get(row.id, holiday => this.setState({holiday: holiday}, () => {
                                                this.fetchImages()
                                                this.fetchFiles()
                                                this.setState({showModal: true})
                                            }))
                                        }} className="pointer">
                                            <FontAwesomeIcon icon="edit" className="bigger"/>
                                        </span>
                                    </div>
                                }}
                            >
                                Options
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </Row>
                }
                {showModal &&
                    <HolidayModal
                        show={this.state.showModal}
                        onHide={this.closeModal}
                        holiday={this.state.holiday}
                        onSave={this.save}
                        onDelete={this.delete}
                        onUpdateName={this.updateName}
                        onUpdateDate={this.updateDate}
                        images={images}
                        docs={docs}
                        uploadFile={this.uploadFile}
                        removeImage={this.removeImage}
                        removeFile={this.removeFile}
                        rotateImage={this.rotateHolidayImage}
                        onUpdateEventType={this.updateEventType}
                        recurring={this.state.holiday && this.state.holiday.recurring}
                    />
                }
                <FetchHolidaysModal
                    show={this.state.showFetchModal}
                    onHide={this.closeFetchModal}
                />
            </Grid>
        );
    }
}

Holidays.propTypes = {
    //myProp: PropTypes.object.isRequired
};

Holidays.defaultProps = {
    //myProp: <defaultValue>
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(api, dispatch)
    };
}

export default connect(null, mapDispatchToProps)(Holidays);