import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Col, ControlLabel, FormGroup, Grid, Row} from "react-bootstrap";
import ResourceComponent from "../../components/ResourceComponent";
import * as api from "./MapSchedulerPageApi";
import {getPastDueInvoices} from "../InvoicesPage/InvoicesApi";
import SplitPane from "react-split-pane";
import {
    convertFloatToHour,
    defaultDateFormat, defaultDateTimeFormat,
    defaultMapParams, defaultTimeFormat,
    mapEvents,
    mapPersonSearch,
    positionCalendarEventsInOrder,
    select
} from "../../common/commonHandlers";
import Select from "react-select";
import moment from "moment/moment";
import TimePicker from "react-bootstrap-time-picker";
import Datetime from "react-datetime";
import FilterModal from "./FilterModal";
import ColorCheckbox from "../Scheduler/ColorCheckbox";
import Dollars from "../../components/Dollars";
import {GoogleMapLoader} from "../../components/GoogleMapLoader";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import '@fullcalendar/bootstrap/main.css';
import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import {debounce} from "throttle-debounce";
import $ from "jquery";
import createApiService from "../../common/clientActionsBuilder";
import SchedulerEventDialog from "../Scheduler/SchedulerEventDialog";
import {geolocated} from 'react-geolocated';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LeadsFilterModal from '../LeadsPage/LeadsFilterModal';
import EventContent from "../Scheduler/Components/EventContent";
import './MapScheduler.scss'
import PastDueInvoicesModal from "../InvoicesPage/PastDueModal/PastDueInvoicesModal";
import Markers from "./Markers";
import GoogleMapElement from "./GoogleMapElement";
import _ from 'lodash';

const isMobile = window.screen.width <= 1024;
const smMobile = window.screen.width <= 450;

let schedulerEventsActions = createApiService('scheduler_events', 'scheduler_event', 'Scheduler events');

const actions = {
    ...schedulerEventsActions,
    ...api,
    getPastDueInvoices
};

class MapSchedulerPage extends ResourceComponent {
    calendarComponentRef = React.createRef();

    period = {dayGridMonth: 'months', timeGridWeek: 'weeks', timeGridDay: 'days'};

    constructor(props, context) {
        super(props, context);
        this.state = {
            resource: {
                time: 28800,
                scheduler_event_date_from: moment(),
                showTodaysRoute: true,
                showTodays: false,
                showTodaysRouteAllUsers: false,
                hide_completed: true,
                hide_invoiced: true
            },
            employees: [],
            showModal: false,
            pastDueInvoicesModal: {
                show: false,
                past_due_invoices: null
            },
            proposal_services: [],
            route_today: [],
            route_today_site: [],
            initialProposalServices: [],
            initialRouteToday: [],
            scheduled: [],
            scheduledServices: [],
            scrollTime: '08:00:00',
            events: [],
            filtered_events: [],
            start: moment(),
            end: moment(),
            LeadsFilterModalShow: false,
            defaultView: 'timeGridDay',
            showEventModal: false,
            calendarVisible: false,
            midDate: moment(),
            checkedRoute: [],
            useCompanyLocation: false,
            hourEventDuration: false,
            activeMarkerData: null,
        };
        this.jq = $.noConflict();
        this.delayedViewRender = debounce(500, this.viewRender);
        this.delayedStopLoadingEvents = debounce(800, () => this.setState({eventsLoading: false}))
    }

    groupBy = (xs, f) => {
        return xs?.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
    };

    handleEvents = (apiEvents) => {
        const {defaultView, events} = this.state;
        const duplicatedEvents = apiEvents.filter((v, i, a) =>
            a.findIndex(t => (t.id === v.id && t.extendedProps.person_id === v.extendedProps.person_id)) !== i
        )
        if (duplicatedEvents.length) {
            duplicatedEvents.map(evt => evt.remove())
        }
        if (defaultView !== 'dayGridMonth') {
            positionCalendarEventsInOrder(this.assignColorToEvents(events), apiEvents.filter(e => !e.allDay))
        }
    }

    handleClick = e => {
        const event = {
            ...e.event.extendedProps,
            start_date: moment(e.event.start),
            end_date: moment(e.event.end),
            id: e.event.id
        }
        this.setState({showEventModal: true, event: event})
    };


    reload = (fitBounds = false, callback) => {
        const {resource} = this.state;
        let ids = this.props.location.state && this.props.location.state.ids

        if (this.calendarComponentRef.current) {
            this.calendarComponentRef.current.getApi().removeAllEvents();
        }

        this.props.actions.load(ids ? {ids, ...resource} : resource, result => {
            callback && callback();
            let grouped = [];
            let groupedRoute = [];
            let groupedRouteBySite = [];
            let scheduled = [];
            let scheduledServices = [];
            let total = 0;
            let phcTotal = 0;
            let phcStops = [];
            let phcHrs = 0;
            let tcTotal = 0;
            let tcStops = [];
            let tcHrs = 0;
            let exclusiveTotal = 0;
            let exclusiveHrs = 0;
            let exclusives = [];
            Object.keys(result.route_today).length > 0 && Object.keys(result.route_today).map((key, index) => {
                let toGroup = result.route_today[key]
                let item = this.groupBy(toGroup, (c) => c.site_id);
                let item_site = this.groupBy(toGroup, (c) => c.site_id);
                groupedRoute.push(item);
                groupedRouteBySite.push(item_site)
            });

            const exclusive = result.exclusive

            const siteOccurrence = {}

            groupedRoute.length > 0 && groupedRoute.map((data, index) => {
                Object.keys(data).map((key, i) => {
                    if (!siteOccurrence[key]) {
                        siteOccurrence[key] = {
                            groupedRoutesCount: 1,
                            count: 1,
                            proposalIndex: index,
                        }
                        data[key].forEach((ps) => {
                            if (!siteOccurrence[key].minDateFrom || ps.date_from < siteOccurrence[key].minDateFrom.value) {
                                siteOccurrence[key].minDateFrom = {
                                    scheduler_event_id: ps.scheduler_event_id,
                                    value: ps.date_from
                                };
                            }
                            ps.skipInNumeration = true
                            scheduled.push(ps.id);
                            scheduledServices.push(ps.proposal_service_id);
                            let singleProposal = {};
                            let singleWorkOrder = {};
                            singleProposal.label = ps.proposal_no;
                            singleProposal.value = ps.id;
                            singleWorkOrder.label = ps.work_order_no;
                            singleWorkOrder.value = ps.work_order_id;
                        })
                    } else {
                        siteOccurrence[key].groupedRoutesCount++;
                        siteOccurrence[key].count++;
                        if (siteOccurrence[key].groupedRoutesCount > 1) {
                            siteOccurrence[key].additionalData = siteOccurrence[key].additionalData ? siteOccurrence[key].additionalData.concat(data[key]) : data[key];
                            siteOccurrence[key].additionalData.forEach(ps => {
                                ps.skipInNumeration = true
                                if (ps.id) {
                                    scheduled.push(ps.id)
                                    scheduled = _.uniq(scheduled);
                                }
                                if (ps.proposal_service_id) {
                                    scheduledServices.push(ps.proposal_service_id);
                                    scheduledServices = _.uniq(scheduledServices)
                                }
                                if (!siteOccurrence[key].minDateFrom || ps.date_from < siteOccurrence[key].minDateFrom.value) {
                                    siteOccurrence[key].minDateFrom = {
                                        scheduler_event_id: ps.scheduler_event_id,
                                        value: ps.date_from
                                    };
                                }
                            })
                        }
                        delete data[key]
                    }

                })
            })

            Object.keys(result.result).map((key) => {
                let toGroup = result.result[key].filter((thing, index, self) => self.findIndex(t => t.proposal_service_id === thing.proposal_service_id) === index);
                let item = this.groupBy(toGroup, (c) => c.site_id);
                grouped.push(item);
                toGroup.forEach(item => {
                    if (item.service_type_name === "GTC" || item.service_type_name === "Tree Care") {
                        tcTotal += +item.service_total
                        tcStops.push(item)
                        tcHrs += +item.man_hours
                    } else if (item.service_type_name === "PHC") {
                        phcTotal += +item.service_total
                        phcStops.push(item)
                        phcHrs += +item.man_hours
                    }
                    total += +item.service_total;
                    if (exclusive && exclusive.includes(item.proposal_service_id)) {
                        exclusiveTotal += +item.service_total;
                        exclusiveHrs += +item.man_hours
                        exclusives.push(item)
                    }
                })
                result.result[key].forEach(item => {
                    let singleProposal = {};
                    let singleWorkOrder = {};
                    singleProposal.label = item.proposal_no;
                    singleProposal.value = item.id;
                    singleWorkOrder.label = item.work_order_no;
                    singleWorkOrder.value = item.work_order_id;
                })
            });

            !resource.showTodaysRoute && grouped.forEach((proposal, proposalIndex) => Object.keys(proposal).forEach(siteId => {
                if (!siteOccurrence[siteId]) {
                    siteOccurrence[siteId] = {
                        groupedRouteCount: 0,
                        count: 1,
                        proposalIndex: proposalIndex + groupedRoute.length,
                    }
                } else {
                    if (siteOccurrence[siteId].groupedRouteCount < 2) {
                        siteOccurrence[siteId].count++;
                    } else {
                        siteOccurrence[siteId].additionalData = siteOccurrence[siteId].additionalData ? siteOccurrence[siteId].additionalData.concat(proposal[siteId]) : proposal[siteId];
                    }
                    delete proposal[siteId]

                }
            }))

            let validatedDate
            if (moment(resource.scheduler_event_date_from).isValid()) {
                let year = moment(resource.scheduler_event_date_from).year()
                let isMomentObject = moment.isMoment(resource.scheduler_event_date_from);
                let length = isMomentObject ? resource.scheduler_event_date_from.format(defaultDateFormat).length : resource.scheduler_event_date_from.length
                if (year > 999 && length > 9) {
                    validatedDate = resource.scheduler_event_date_from
                } else {
                    validatedDate = moment()
                }
            } else {
                validatedDate = moment()
            }

            const initialProposalServices = resource.showTodaysRoute ? groupedRoute : groupedRoute.concat(grouped)
            Object.keys(siteOccurrence).map(siteId => {
                const {groupedRoutesCount, count, proposalIndex, additionalData, minDateFrom} = siteOccurrence[siteId];
                if (additionalData) {
                    initialProposalServices[proposalIndex][siteId] = _.uniq(initialProposalServices[proposalIndex][siteId].concat(additionalData))
                }
                if (minDateFrom) {
                    const numerationEvent = initialProposalServices[proposalIndex][siteId].find(ps => ps.scheduler_event_id === minDateFrom.scheduler_event_id)
                    numerationEvent.skipInNumeration = false
                    if (resource.showTodaysRoute ? groupedRoutesCount > 1 : count > 1) {
                        numerationEvent.mixedMarker = true
                    }
                }
            })
            this.setState({
                initialProposalServices,
                initialRouteToday: groupedRoute,
                useCompanyLocation: result.use_company_location,
                hourEventDuration: result.hour_event_duration,
                start_date: result.start_date,
                proposal_services: initialProposalServices,
                route_today: groupedRoute,
                route_today_site: groupedRouteBySite,
                scheduled: scheduled,
                scheduledServices: scheduledServices,
                midDate: validatedDate,
                total: total,
                tcTotal: tcTotal,
                tcStops: tcStops,
                tcHrs: tcHrs,
                phcTotal: phcTotal,
                phcStops: phcStops,
                phcHrs: phcHrs,
                exclusiveTotal: exclusiveTotal,
                exclusiveHrs: exclusiveHrs,
                exclusives: exclusives,
                unscheduledEvents: result.unscheduled_events,
                unscheduledHours: result.unscheduled_hours,
                loaded: fitBounds,
            }, () => {
                this.handleToggleClose()
                this.delayedStopLoadingEvents()
            })
        });
        if (resource.person_id || resource.showTodaysRouteAllUsers) {
            this.loadEvents();
        }
    };

    componentDidMount() {
        let {resource} = this.state;
        this.guestSearch = this.buildSearchComponent(
            'scheduler_event_guests',
            this.props.actions.search,
            mapPersonSearch
        );

        this.selectEvent = this.selectMarker('events', (e, x) => (e.id === x.id && e.person_id === x.person_id));
        this.props.actions.loadContextData(data => this.setState(data, () => {
            const employee = data.employees.find(e => e.label === this.props.state.auth.name);
            if (!resource.person_id) {
                resource.person_id = employee && employee.value;
            }
            resource.person_id = null
            this.setState(resource, () => {
                this.reload(false, () => this.setState({initial_load_happened: true}));
            });
        }));
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.view?.type && ((prevState.view?.type !== 'timeGridDay' && this.state.view?.type === 'timeGridDay') ||
            (prevState.view?.type !== 'timeGridWeek' && this.state.view?.type === 'timeGridWeek') ||
            (prevState.view?.type !== 'dayGridMonth' && this.state.view?.type === 'dayGridMonth'))) {
            this.updateDate(moment(this.state.midDate.startOf('day')))
        }
    }

    viewRender = () => {
        const {view} = this.state;
        const {start, end} = this.state;
        const differentStart = !moment(view.activeStart).isSame(start);
        const differentEnd = !moment(view.activeEnd).isSame(end);
        if ((differentStart || differentEnd)) {
            this.setState({
                start: moment(view.activeStart),
                end: !moment(view.activeEnd),
                defaultView: view.type
            }, () => {
                this.reload();
            });
        }
    };

    closeFilterModal = () => {
        this.setState({showModal: false});
    };
    updateResourceAttributes = (data) => {
        const {resource} = this.state;
        resource.service_type = data.service_type ? data.service_type : null;
        resource.service_id = data.service_id ? data.service_id : null;
        resource.priority_id = data.priority_id ? data.priority_id : null;
        resource.equipment_ids = data.equipment_ids ? data.equipment_ids : null;
        resource.customer_id = data.customer_id ? data.customer_id : null;
        resource.ex_service_id = data.ex_service_id ? data.ex_service_id : null;
        resource.ex_priority_id = data.ex_priority_id ? data.ex_priority_id : null;
        resource.ex_equipment_ids = data.ex_equipment_ids ? data.ex_equipment_ids : null;
        resource.scheduled_from = data.scheduled_from ? data.scheduled_from : null;
        resource.scheduled_to = data.scheduled_to ? data.scheduled_to : null;
        resource.promised_from = data.promised_from ? data.promised_from : null;
        resource.promised_to = data.promised_to ? data.promised_to : null;
        resource.arborist_id = data.arborist_id ? data.arborist_id : null;
        resource.hours = data.service_type ? (data.hours ? data.hours : null) : null;
        resource.zip = data.zip ? data.zip : null;
        resource.city = data.city ? data.city : null;
        resource.proposal_id = data.proposal_id ? data.proposal_id : null;
        resource.work_order_id = data.work_order_id ? data.work_order_id : null;
        resource.include_scheduled = data.include_scheduled ? data.include_scheduled : null;
        resource.hide_completed = data.hide_completed ? data.hide_completed : null;
        resource.hide_invoiced = data.hide_invoiced ? data.hide_invoiced : null;
        resource.eq = data.eq ? data.eq : null;
        resource.customer_type = data.customer_type ? data.customer_type : null;
        resource.show_exclusive = data.show_exclusive ? data.show_exclusive : null;
        resource.plant_id = data.plant_id ? data.plant_id : null;
        resource.ex_plant_id = data.ex_plant_id ? data.ex_plant_id : null;
        resource.reason_id = data.reason_id ? data.reason_id : null;

        this.setState({resource: resource, showModal: false}, this.reload);
    };

    handleToggleOpen = async (proposals, marker_event, callback) => {
        this.handleToggleClose();
        let {proposal_services, route_today, resource, scheduledServices, view} = this.state;
        let stateToUpdate = {
            activeMarkerData: {
                site_id: marker_event.site_id,
                scheduler_event_id: marker_event.scheduler_event_id
            }
        }

        let new_proposal_services = [...proposal_services];
        const siteOccurrence = {}
        await Promise.all(proposals.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.id === item.id
                ))
        ).map(proposal =>
            new Promise((resolve) => {
                if (proposal.id) {
                    this.props.actions.loadProposalServices(proposal.id, resource, (result) => {
                        let minDateFrom;
                        new_proposal_services.forEach((data, proposalServiceIndex) => {
                                Object.keys(data).forEach((key) => {
                                    if (+key === +proposal.site_id) {
                                        const thisSiteOccurence = siteOccurrence[key] || {}
                                        thisSiteOccurence.proposals = thisSiteOccurence.proposals || []
                                        if (!thisSiteOccurence.proposals.some(pid => pid === proposal.id)) {
                                            thisSiteOccurence.proposals.push(proposal.id);
                                            let resultWithPersonId = _.uniq(result.concat(data[key].filter(m => !m.id)).map((r, i) => {
                                                const mixedMarker = data[key].some(k => k.mixedMarker)
                                                if ((!minDateFrom || r.date_from < minDateFrom.value) && (r.has_scheduler_event_guest || !r.id) && scheduledServices.includes(r.proposal_service_id)) {
                                                    if (moment(r.date_from).isSameOrAfter(moment(view.activeStart)) && moment(r.date_from).isSameOrBefore(moment(view.activeEnd))) {
                                                        minDateFrom = {
                                                            scheduler_event_id: r.scheduler_event_id,
                                                            value: r.date_from,
                                                            key,
                                                            proposalServiceIndex
                                                        };
                                                    }
                                                }
                                                return {
                                                    ...r,
                                                    person_id: marker_event.person_id,
                                                    scheduler_event_guest_id: marker_event.scheduler_event_guest_id,
                                                    mixedMarker,
                                                    skipInNumeration: true
                                                }
                                            }))
                                            if (thisSiteOccurence.proposals.length !== 1) {
                                                resultWithPersonId = data[key].concat(resultWithPersonId)
                                            }
                                            route_today.map(route => {
                                                Object.keys(route).map(routeKey => {
                                                    if (routeKey === proposal.site_id) {
                                                        resultWithPersonId = resultWithPersonId.concat(route[proposal.site_id]);
                                                    }
                                                })
                                            });
                                            siteOccurrence[key] = thisSiteOccurence;
                                            data[key] = resultWithPersonId;
                                        }
                                    }
                                });
                            }
                        )
                        if (minDateFrom) {
                            new_proposal_services[minDateFrom.proposalServiceIndex][minDateFrom.key].find(ps => ps.scheduler_event_id === minDateFrom.scheduler_event_id).skipInNumeration = false
                        }
                        stateToUpdate = {
                            ...stateToUpdate,
                            proposal_services: new_proposal_services,
                            proposalSites: proposals.map(p => p.site_id),
                        }
                        if (this.state.defaultView === "dayGridMonth" && marker_event) {
                            stateToUpdate = {
                                ...stateToUpdate,
                                personIdForMonth: marker_event.scheduler_event_guest_id && marker_event.person_id
                            }
                        }
                        if (this.state.defaultView === "dayGridMonth") {
                            const filtered_events = this.state.events.filter(e => this.state.events?.filter(e => e.id === marker_event?.scheduler_event_id)?.map(e => e.person_id).includes(e.person_id))
                            stateToUpdate = {...stateToUpdate, filtered_events};
                        }
                        resolve();
                    })
                } else {
                    stateToUpdate = {
                        ...stateToUpdate,
                        proposal_services: new_proposal_services,
                        proposalSites: proposals.map(p => p.site_id),
                    }
                    if (this.state.defaultView === "dayGridMonth" && marker_event) {
                        stateToUpdate = {
                            ...stateToUpdate,
                            personIdForMonth: marker_event.scheduler_event_guest_id && marker_event.person_id
                        }
                    }
                    if (this.state.defaultView === "dayGridMonth") {
                        const filtered_events = this.state.events.filter(e => this.state.events?.filter(e => e.id === marker_event?.scheduler_event_id)?.map(e => e.person_id).includes(e.person_id))
                        stateToUpdate = {...stateToUpdate, filtered_events};
                    }
                    resolve();
                }
            })));
        this.setState(stateToUpdate)
        callback && callback()
    };


    handleToggleClose = () => {
        this.setState({
            proposalSites: [],
            filtered_events: [],
            personIdForMonth: null,
            activeMarkerData: null,
            checkedRoute: []
        });
    };
    handleToggleRoute = (proposal_service) => {
        let {proposal_services, checkedRoute} = this.state;
        let new_proposal_services = [...proposal_services];
        new_proposal_services.map((data, index) =>
            Object.keys(data).map((key, i) =>
                data[key].forEach((ps, idx) => {
                    if (ps.name === proposal_service.name && ps.proposal_service_id === proposal_service.proposal_service_id) {
                        ps.toRoute = !ps.toRoute;
                        if (!checkedRoute.includes(proposal_service.proposal_service_id)) {
                            checkedRoute.push(proposal_service.proposal_service_id);
                        }
                        this.setState({checkedRoute: checkedRoute})
                    }
                })
            ));
        this.setState({
            proposal_services: new_proposal_services
        });
    };

    handleToggleRouteRemoveHelper = (ps) => {
        const {checkedRoute} = this.state;
        if (checkedRoute && checkedRoute.includes(ps.proposal_service_id)) {
            const index = checkedRoute.indexOf(ps.proposal_service_id);
            checkedRoute.splice(index, 1);
            this.setState({checkedRoute: checkedRoute})
        } else {
            this.handleToggleRoute(ps)
        }
    };

    updateEvent = e => {
        e = e.event
        const {resource, events} = this.state;
        const event = {};
        event.all_day = e.allDay;
        let UTCFormat = 'YYYY-MM-DD[T]HH:mm:ss[Z]'
        if (event.all_day) {
            event.date_from = moment(e.start).startOf('day').format(UTCFormat);
            event.date_to = moment(e.start).endOf('day').format(UTCFormat);
        }
        else{
            event.date_from = e.start;
            event.date_to = e.end ? e.end : moment(e.start).add(1, 'hour');
        }
        if (e.id) {
            this.setState({eventsLoading: true})
            event.id = e.id;
            this.props.actions.updateEvent(event, () => this.reload(true));
        } else {
            this.setState({resource: {...resource, ...event}});
        }
        const toUpdate = events.find(x => +x.id === +e.id);
        toUpdate.date_from = e.start;
        toUpdate.start = e.start;
        toUpdate.all_day = e.allDay
        toUpdate.date_to = e.end;
        toUpdate.end = e.end;
        this.setState({events: events, scrollTime: this.getScrollTime(toUpdate)});
    };

    assignColorToEvents = (events) => {
        return events.map(e => {
            const color = this.getColor(e);
            const event = {
                ...e,
                backgroundColor: e.selected ? '#f8e500' : color,
                borderColor: e.selected ? '#f8e500' : color,
                textColor: e.selected ? 'black' : 'white',
            };
            event.event_id = `${event.id}_${event.backgroundColor}`
            return event
        });
    }

    loadEvents = () => {
        const {resource} = this.state;
        const scheduler_event_guests = resource.person_id;
        if (moment.isMoment(resource.scheduler_event_date_from)) {
            this.props.actions.loadEvents(
                {
                    scheduler_event_guests,
                    ...resource,
                    from: resource.scheduler_event_date_from.format(),
                    to: moment(resource.scheduler_event_date_from).add(1, 'day').format(),
                }, events => {
                    this.setState({events: events.map(mapEvents)});
                });
        }
    };

    giveUtc = (start) => {
        var t = moment().format(defaultDateFormat)
        var t1 = t + " " + start
        return moment(t1, defaultDateTimeFormat).format()
    }

    pastDueInvoicesModalOnSubmit = () => {
        const {sitesItem} = this.state.pastDueInvoicesModal
        this.setState({pastDueInvoicesModal: {show: false}})
        this.save(sitesItem.filter(x => this.state.checkedRoute.includes(x.proposal_service_id)))
    }

    onAddToRoute = (sitesItem) => {
        const proposalId = sitesItem[0].id
        if (proposalId) {
            this.props.actions.getPastDueInvoices({proposal_id: proposalId}, (response) => {
                const {past_due, past_due_invoices} = response
                if (past_due) {
                    this.setState({pastDueInvoicesModal: {show: true, past_due_invoices, sitesItem}})
                } else {
                    this.save(sitesItem.filter(x => this.state.checkedRoute.includes(x.proposal_service_id)))
                }
            })
        }


    }

    save = (proposal_services) => {
        let {resource, checkedRoute, events, hourEventDuration} = this.state;
        let date_from;
        const busyCalendarHours = events.map(e => {
            const start = moment(e.start).format('LT')
            const end = moment(e.end).format('LT')

            return {
                start: start,
                end: end
            }
        })

        let selectedPsDuration;

        if (proposal_services?.length > 0) {
            selectedPsDuration = proposal_services.map(p => p.man_hours).reduce((a, b) => a + b, 0)
        }

        const availableHours = this.findFreeCalendarSlots(busyCalendarHours, resource.time, selectedPsDuration)


        if (events && events.length === 0) {
            date_from = resource.scheduler_event_date_from.startOf('day').add(resource.time / 3600, 'hours').format();
        } else if ((events && events.length > 0) && (availableHours && availableHours.length > 0)) {
            date_from = resource.scheduler_event_date_from.startOf('day').add(availableHours[0].start / 3600, 'hours').format();
        }

        proposal_services = proposal_services.filter((thing, index, self) => self.findIndex(t => t.proposal_service_id === thing.proposal_service_id) === index);

        if (!date_from) {
            date_from = resource.scheduler_event_date_from.startOf('day').add(resource.time / 3600, 'hours').format()
        }
        if (proposal_services.length === 1) {
            let woHours = proposal_services[0]?.man_hours
            let date_to;

            if (hourEventDuration) {
                date_to = moment(date_from).add(1, 'hours').format();
            } else {
                date_to = moment(date_from).add(woHours ? this.convertWoDuration(woHours) : 60, "minutes").format()
            }

            if (proposal_services[0].work_order_id) {
                this.props.actions.save({
                    date_from,
                    date_to,
                    all_day: false,
                    person_id: resource.person_id,
                    scheduler_event_resource_id: proposal_services[0].work_order_id,
                    name: `Work Order #${proposal_services[0].work_order_no}`,
                    site_id: proposal_services[0].site_id,
                    scheduler_event_type_id: 1,
                }, () => {
                    const index = checkedRoute.indexOf(proposal_services[0].proposal_service_id);
                    if (index > -1) {
                        checkedRoute.splice(index, 1);
                    }
                    this.setState({checkedRoute: checkedRoute});
                    this.reload(true);
                })
            } else {
                this.props.actions.addWo(null, [proposal_services[0].proposal_service_id], (workOrder) => {
                    this.props.actions.save({
                        date_from,
                        date_to,
                        all_day: false,
                        person_id: resource.person_id,
                        scheduler_event_resource_id: workOrder.id,
                        name: workOrder.work_order_no ? `Work Order #${workOrder.work_order_no}` : `${proposal_services[0].name}`,
                        site_id: proposal_services[0].site_id,
                        scheduler_event_type_id: 1
                    }, () => {
                        const index = checkedRoute.indexOf(proposal_services[0].proposal_service_id);
                        if (index > -1) {
                            checkedRoute.splice(index, 1);
                        }
                        this.setState({checkedRoute: checkedRoute});
                        this.reload(true);
                    })
                })
            }
        } else {
            proposal_services = proposal_services.filter((thing, index, self) => self.findIndex(t => t.proposal_service_id === thing.proposal_service_id) === index);
            let woHours = proposal_services.reduce((a, b) => a + (+b['man_hours'] || 0), 0);
            let date_to;

            if (hourEventDuration) {
                date_to = moment(date_from).add(1, 'hours').format();
            } else {
                date_to = date_to = moment(date_from).add(woHours ? this.convertWoDuration(woHours) : 60, "minutes").format()
            }

            let ids = [];
            let idsWithWo = [];
            proposal_services.forEach(ps => {
                ps.work_order_id && idsWithWo.push(ps.proposal_service_id);
                ids.push(ps.proposal_service_id)
            });

            if (proposal_services[0].work_order_id && proposal_services.every(ps => ps.work_order_id === proposal_services[0].work_order_id)) {
                this.props.actions.save({
                    date_from,
                    date_to,
                    all_day: false,
                    person_id: resource.person_id,
                    scheduler_event_resource_id: proposal_services[0].work_order_id,
                    name: proposal_services[0].work_order_no ? `Work Order #${proposal_services[0].work_order_no}` : `${proposal_services[0].name}`,
                    site_id: proposal_services[0].site_id,
                    scheduler_event_type_id: 1
                }, () => {
                    let new_proposal_services = [...this.state.proposal_services];
                    new_proposal_services.map((data, index) =>
                        Object.keys(data).map((key, i) =>
                            data[key].forEach((ps, idx) => {
                                if (ps.toRoute && ps.id === proposal_services[0].id)
                                    ps.toRoute = null;
                            })
                        ));
                    this.setState({
                        proposal_services: new_proposal_services
                    });
                    this.reload(true);
                })
            } else {
                this.props.actions.removeProposalServicesFromWo(idsWithWo, () => {
                    this.props.actions.addWo(null, [ids[0]], (result) => {
                        this.props.actions.addWo(result.work_order_no, ids.slice(1), () => {
                            this.props.actions.save({
                                date_from,
                                date_to,
                                all_day: false,
                                person_id: resource.person_id,
                                scheduler_event_resource_id: result.work_order_id,
                                name: result.work_order_no ? `Work Order #${result.work_order_no}` : `${proposal_services[0].name}`,
                                site_id: proposal_services[0].site_id,
                                scheduler_event_type_id: 1
                            }, () => {
                                let new_proposal_services = [...this.state.proposal_services];
                                new_proposal_services.map((data, index) =>
                                    Object.keys(data).map((key, i) =>
                                        data[key].forEach((ps, idx) => {
                                            if (ps.toRoute && ps.id === proposal_services[0].id)
                                                ps.toRoute = null;
                                        })
                                    ));
                                this.setState({
                                    proposal_services: new_proposal_services
                                });
                                this.reload(true);
                            })
                        })
                    })
                }, () => {
                    proposal_services.forEach((ps, idx) => {
                        const index = checkedRoute.indexOf(ps[idx].proposal_service_id);
                        if (index > -1) {
                            checkedRoute.splice(index, 1);
                        }
                    });
                    this.setState({checkedRoute: checkedRoute});
                    this.reload(true);
                })
            }
        }
        this.setState({checkedRoute: []})
    };

    getScrollTime(event) {
        return moment(event.start).add(-1, 'hour').format('HH:mm:ss');
    };

    renderTotal = () => {
        const {
            resource,
            events,
            initialRouteToday,
            initialProposalServices,
            checkedRoute,
            scheduledServices
        } = this.state;
        let total = 0;
        let serviceCount = 0;
        let hours = 0;
        if (resource.showTodaysRouteAllUsers) {
            initialRouteToday && initialRouteToday.length > 0 && initialRouteToday.map((data, index) =>
                Object.keys(data).map((key, i) =>
                    _.uniqBy(data[key], 'proposal_service_id').forEach((ps, idx) => {
                        if (scheduledServices.includes(ps.proposal_service_id)) {
                            total += +ps.service_total;
                            hours += +ps.man_hours;
                            if (ps.proposal_service_id) {
                                serviceCount++;
                            }
                        }
                    })
                ));
        } else {
            initialRouteToday && initialRouteToday.length > 0 && initialRouteToday.map((data, index) =>
                Object.keys(data).map((key, i) =>
                    _.uniqBy(data[key], 'proposal_service_id').forEach((ps, idx) => {
                        if (ps.person_id === resource.person_id && scheduledServices.includes(ps.proposal_service_id)) {
                            total += +ps.service_total;
                            hours += +ps.man_hours;
                            if (ps.proposal_service_id) {
                                serviceCount++;
                            }
                        }
                    })
                ));
        }

        initialProposalServices.map((data, index) =>
            Object.keys(data).map((key, i) =>
                data[key].forEach((ps, idx) => {
                    if (checkedRoute.includes(ps.proposal_service_id)) {
                        total += +ps.service_total;
                        hours += +ps.man_hours;
                        if (ps.proposal_service_id) {
                            serviceCount++;
                        }
                    }
                })
            ));
        const stops = _.uniqBy(events.filter((event, index) => event.site_latitude && events.findIndex(t => t.site_latitude === event.site_latitude) === index), 'site_latitude')
        return <Row className={'map-total vcenter bottom10'}>
            {initialProposalServices && initialProposalServices.length > 0 && <Col xs={12}>
                <Row className={'vcenter text-center'}>
                    <Col xs={3}>
                        Route For:
                    </Col>
                    <Col xs={2}>
                        Services:
                    </Col>
                    <Col xs={2}>
                        Stops:
                    </Col>
                    <Col xs={2}>
                        Hours:
                    </Col>
                    <Col xs={3}>
                        Dollars:
                    </Col>
                </Row>
                <Row className={'vcenter text-center'}>
                    <Col xs={3}>
                        {resource && resource.scheduler_event_date_from && moment(resource.scheduler_event_date_from).format(defaultDateFormat)}
                    </Col>
                    <Col xs={2}>
                        {serviceCount}
                    </Col>
                    <Col xs={2}>
                        {stops.length}
                    </Col>
                    <Col xs={2}>
                        {hours.toFixed(2)}
                    </Col>
                    <Col xs={3}>
                        <Dollars amount={total}/>
                    </Col>
                </Row>
            </Col>}
            <Col xs={2}>
                <a href={this.prepareDaysRoute(stops, stops.length)} target="_blank"
                   className={stops.length < 1 ? 'disabled route' : 'route'}
                   onClick={e => {
                       stops.length < 1 && e.preventDefault();
                   }}>
                    Route on Google
                </a>
            </Col>
        </Row>
    };

    prepareDaysRoute = (selectedServices, count) => {
        const {isGeolocationAvailable, isGeolocationEnabled, coords, client} = this.props;
        const {useCompanyLocation} = this.state;
        let myLongitude = null;
        let myLatitude = null;
        if (isGeolocationAvailable && isGeolocationEnabled && coords && !useCompanyLocation) {
            myLongitude = coords.longitude;
            myLatitude = coords.latitude;
        } else if (useCompanyLocation) {
            myLongitude = client && client.organization_longitude;
            myLatitude = client && client.organization_latitude;
        }
        if (count < 1) {
            return null;
        }
        let src = `https://www.google.com/maps/dir/${myLatitude},${myLongitude}/${selectedServices[count - 1].site_latitude},${selectedServices[count - 1].site_longitude}`;
        let wavySrc = '';

        if (selectedServices && selectedServices.length > 0) {
            selectedServices = selectedServices.sort((a, b) => {
                if (new Date(a.start).getTime() > new Date(b.start).getTime()) {
                    return 1
                } else {
                    return -1
                }
            })
        }

        if (count === 1) {
            return src
        } else if (count >= 2) {
            src = `https://www.google.com/maps/dir/${myLatitude},${myLongitude}/`
            selectedServices && selectedServices.length > 0 && selectedServices.forEach(s => {
                wavySrc += `${s.site_latitude},${s.site_longitude}/`
            });
            let result = src + wavySrc;
            //remove '/' character from the end of the url
            return result.slice(0, result.length - 1);
        }
    };

    getColor = e => {
        const guestOptions = this.guestSearch ? this.guestSearch.searchOptions() : [];
        const guest = select(guestOptions, e.person_id);
        let employee = select(this.state.employees, e.person_id);
        let result = '#3a87ad';
        if (guest) {
            result = guest.color;
        } else if (employee) {
            result = employee.color
        }
        return result;
    };

    toggleCalendar = () => {
        const {calendarVisible} = this.state;
        this.setState({calendarVisible: !calendarVisible})
    };

    updateDate(date) {
        const {view, resource} = this.state;
        resource.scheduler_event_date_from = date;
        resource.calendar_view_type = this.state.view?.type;
        let calendarApi = this.calendarComponentRef.current.getApi();
        calendarApi.gotoDate(date.format());
        this.setState({
            resource: resource,
            start: moment(view.activeStart),
            end: moment(view.activeEnd),
            defaultView: view.type
        }, () => {
            this.setState({events: []}, () => {
                this.reload()
            })
        })
    }

    goToProposalStatus = (id) => {
        const url = new URL(window.location.href).origin
        window.open(`${url}/proposal_service_status/${id}`, '_blank');
    };

    roundFloatNumber(num, decimal_threshold) {
        const decimal_part = num - Math.floor(num);
        if (decimal_part >= decimal_threshold) {
            return Math.ceil(num);
        } else {
            return Math.floor(num);
        }
    }

    findFreeCalendarSlots = (arr, initialTime, selectedPsDuration) => {
        arr.sort((a, b) => {
            const utcA = this.giveUtc(a.start)
            const utcB = this.giveUtc(b.start)
            if (utcA < utcB) {
                return -1
            }
            if (utcA > utcB) {
                return 1
            }
            return 0
        })

        let availableTimeArray = []

        let endTimeFarthest = moment(this.giveUtc("0.00 AM"))
        let startTimeMinimum = moment(this.giveUtc("12.59 PM"))
        arr.forEach((element, index) => {
            let currentEndTime = moment(this.giveUtc(element.end))
            const currentStartTime = moment(this.giveUtc(element.start))
            if (currentStartTime.isBefore(startTimeMinimum)) {
                startTimeMinimum = currentStartTime
            }
            if (currentEndTime.isAfter(endTimeFarthest)) {
                endTimeFarthest = currentEndTime
            }
            if (index === arr.length - 1) {
                if (arr.length === 1) {
                    availableTimeArray.push({
                        start: "00:00 AM",
                        end: currentStartTime.format(defaultTimeFormat)
                    })
                }
                availableTimeArray.push({
                    start: endTimeFarthest.format(defaultTimeFormat),
                    end: "11.59 PM"
                })

            } else {
                const nextBusyTime = arr[index + 1]
                const nextStartTime = moment(this.giveUtc(nextBusyTime.start))
                if (index === 0) {
                    availableTimeArray.push({
                        start: "00:00 AM",
                        end: currentStartTime.format(defaultTimeFormat)
                    })
                }
                let endTimeToCompare = currentEndTime.isBefore(endTimeFarthest) ?
                    endTimeFarthest :
                    currentEndTime
                if (endTimeToCompare.isBefore(nextStartTime)) {
                    availableTimeArray.push({
                        start: endTimeToCompare.format(defaultTimeFormat),
                        end: nextStartTime.format(defaultTimeFormat)
                    })
                }

            }
        })

        availableTimeArray = availableTimeArray.map((y, i) => {
            const start_at = moment(y.start, ["HH:mm A"]).format("HH:mm:ss")
            const end_at = moment(y.end, ["HH:mm A"]).format("HH:mm:ss")

            const start = moment.duration(start_at).asSeconds()
            const end = moment.duration(end_at).asSeconds()
            let duration = moment.duration(moment(y.end, "HH:mm a").diff(moment(y.start, "HH:mm a"))).asHours()

            return {
                start: initialTime > start ? initialTime : start,
                end: end,
                duration: duration >= selectedPsDuration ? duration : this.roundFloatNumber(duration, 0.95)
            }
        })

        availableTimeArray = availableTimeArray.filter(x => x.duration >= 0.25 && x.start < x.end)
        return availableTimeArray
    }

    convertWoDuration = (woHours) => {
        if (woHours) {
            woHours = convertFloatToHour(woHours)
        }

        const hours = woHours.toString().split(".")[0] * 60
        const minutes = woHours.toString().split(".")[1]
        return (+hours) + (+minutes)
    }

    render() {
        const {
            resource,
            employees,
            proposal_services,
            defaultView,
            scheduled,
            scheduledServices,
            filterDate,
            showEventModal,
            calendarVisible,
            midDate,
            route_today_site,
            LeadsFilterModalShow,
            proposalSites,
            start_date,
            exclusiveHrs,
            exclusives,
            unscheduledEvents,
            unscheduledHours,
            phcStops,
            phcHrs,
            tcStops,
            tcHrs,
            pastDueInvoicesModal,
            filtered_events,
            personIdForMonth,
            events,
            activeMarkerData,
        } = this.state;
        const {client} = this.props;
        const employee = employees.find(e => e.value === resource.person_id);
        const employeeOptions = employees.filter(x => x.label !== 'Not assigned');
        let sites = [];
        let allServices = [];


        proposal_services && proposal_services.length > 0 && proposal_services.map((data, index) =>
            Object.values(data).forEach((value) => {
                if (value?.length > 0) {
                    value[0].site_selected = value.filter(x => x.selected).length > 0;
                    value[0].site_toRoute = value.filter(x => x.toRoute).length > 0;
                    sites.push(value[0]);
                    allServices = allServices.concat(value);
                }
            }));

        const syncCalendar = this.props.actions.syncCalendar;
        const toggleCalendar = this.toggleCalendar;
        const reload = this.reload;
        let groupedBySite = this.groupBy(allServices, (c) => c.site_id);
        const clientStartTime = this.state && start_date

        let start_time
        if (clientStartTime) {
            start_time = moment.utc((clientStartTime) * 1000).format('HH:mm:ss')
        }

        const balance = {}

        if (unscheduledEvents) {
            if (phcStops) balance.phcStops = Object.keys(this.groupBy(phcStops, (c) => c.site_id)).length || 0
            if (tcStops) balance.tcStops = Object.keys(this.groupBy(tcStops, (c) => c.site_id)).length || 0

            balance.events = unscheduledEvents
            balance.hours = unscheduledHours && unscheduledHours.toFixed(2) / 1

            balance.phcHrs = +phcHrs.toFixed(2)
            balance.tcHrs = +tcHrs.toFixed(2)

            balance.exclusiveStops = exclusives?.length > 0 && Object.keys(this.groupBy(exclusives, (c) => c.site_id))?.length || 0
            balance.exclusiveHrs = exclusiveHrs && exclusiveHrs.toFixed(2) / 1 || 0
        }

        const compareEvents = (eventA, eventB) => {
            if (eventA.person_id !== eventB.person_id) {
                return eventA.person_id - eventB.person_id;
            } else {
                return eventA.scheduler_event_guest_id - eventB.scheduler_event_guest_id;
            }
        }

        const flattenPSEvents = (() => {
            return proposal_services.flatMap(ps => Object.keys(ps).flatMap(keys => ps[keys])).filter(ps => scheduledServices.includes(ps.proposal_service_id)).filter(ps => ps.skipInNumeration ? false : ps.latitude && ps.scheduler_event_guest_id && (resource.calendar_view_type !== "dayGridMonth" ? true : personIdForMonth === ps.person_id || resource.person_id === ps.person_id)).sort((a, b) => new Date(a.date_from) - new Date(b.date_from))
        })()

        const renderMarkers = (proposal_services) => {
            const markerProps = {
                groupedBySite,
                route_today_site,
                mappedEvents: events,
                resource,
                compareEvents,
                flattenPSEvents,
                personIdForMonth,
                scheduledServices,
                handleToggleOpen: this.handleToggleOpen,
                handleToggleClose: this.handleToggleClose,
                groupBy: this.groupBy,
                goToProposalStatus: this.goToProposalStatus,
                handleToggleRouteRemoveHelper: this.handleToggleRouteRemoveHelper,
                reload: this.reload,
                onAddToRoute: this.onAddToRoute,
                locationState: this.props.location.state,
                checkedRoute: this.state.checkedRoute,
                showExclusive: this.state.resource.show_exclusive,
                removePsFromWo: this.props.actions.removePsFromWo,
                getColor: this.getColor,
                proposal_services,
                scheduled,
                activeMarkerData,
            }
            return <Markers {...markerProps}/>
        }

        const calendarEvents = this.state.defaultView !== "dayGridMonth" || resource.person_id ? events.sort(compareEvents) : filtered_events.sort(compareEvents)
        return (
            <Grid fluid id='map-scheduler' xs={12}>
                {pastDueInvoicesModal?.show &&
                    <PastDueInvoicesModal
                        show={pastDueInvoicesModal.show}
                        onHide={() => {
                            this.setState({pastDueInvoicesModal: {show: false}})
                        }}
                        onSubmit={this.pastDueInvoicesModalOnSubmit}
                        pastDueInvoices={pastDueInvoicesModal.past_due_invoices}
                    />}
                <Row>
                    <Col md={12}>
                    </Col>
                </Row>
                <Row className="vcenter">
                    <Col md={4} className="vcenter full-width">
                        <h2 className="no-top-margin mr-9">Map Scheduler</h2>
                    </Col>
                    <Col md={8} className="text-right full-width">
                        <Button
                            bsStyle="primary" className="no-top-margin small-margin"
                            onClick={() => {
                                this.setState({
                                    LeadsFilterModalShow: !this.state.LeadsFilterModalShow,
                                    resource
                                })
                            }}>
                            New Schedule
                            <FontAwesomeIcon icon="plus" className="small-margin"/>
                        </Button>
                        <Button
                            className="pointer no-top-margin"
                            bsStyle="warning"
                            onClick={() => {
                                this.setState({showModal: true})
                            }}>
                            Filter {" "}<FontAwesomeIcon icon="search" className="small-margin"/>
                        </Button>
                    </Col>
                </Row>
                <hr className={'mb-10-mt-0'}/>
                <LeadsFilterModal
                    title="Add Schedule"
                    show={LeadsFilterModalShow}
                    onHide={() => {
                        this.setState({
                            LeadsFilterModalShow: !this.state.LeadsFilterModalShow,
                        })
                    }}
                    overflow={{overflow: 'visible'}}

                    closeButton={() => {
                        this.setState({
                            LeadsFilterModalShow: !this.state.LeadsFilterModalShow
                        })
                    }}
                >
                    <Row>
                        <Col className="bottom15">
                            <FormGroup controlId="formHorizontalEmail">
                                <Col componentClass={ControlLabel} sm={2} className="top7"
                                     style={{textAlign: 'right', fontSize: isMobile ? 12 : null}}>
                                    For
                                </Col>
                                <Col sm={10}>
                                    <Select className="Select full-width" classNamePrefix="select"
                                            options={employeeOptions}
                                            isClearable
                                            autoFocus
                                            value={select(employeeOptions, employee && employee.value)}
                                            onChange={e => {
                                                resource.person_id = e ? e.value : null;
                                                resource.showTodaysRouteAllUsers = false
                                                if (!resource.person_id) {
                                                    this.setState({events: []});
                                                }
                                                this.setState(resource, () => {
                                                    this.reload();
                                                });
                                            }}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="bottom15">
                            <FormGroup controlId="Date">
                                <Col componentClass={ControlLabel} sm={2} className="top7"
                                     style={{textAlign: 'right', fontSize: isMobile ? 12 : null}}>
                                    On
                                </Col>
                                <Col sm={10}>
                                    <Datetime
                                        dateFormat={defaultDateFormat}
                                        timeFormat={false}
                                        value={resource.scheduler_event_date_from}
                                        inputProps={{placeholder: 'Date'}}
                                        onChange={this.dateResourceAttr('scheduler_event_date_from', () => {
                                            if (moment(resource.scheduler_event_date_from).isValid()) {
                                                let year = moment(resource.scheduler_event_date_from).year()
                                                let isMomentObject = moment.isMoment(resource.scheduler_event_date_from);
                                                let length = isMomentObject ? resource.scheduler_event_date_from.format(defaultDateFormat).length : resource.scheduler_event_date_from.length
                                                if (year > 999 && length > 9) {
                                                    this.updateDate(resource.scheduler_event_date_from)
                                                    this.reload();
                                                }
                                            }
                                        })}

                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="bottom15">
                        <Col className="bottom15">
                            <FormGroup controlId="TimePicker">
                                <Col componentClass={ControlLabel} sm={2} className="top7"
                                     style={{textAlign: 'right', fontSize: isMobile ? 12 : null}}>
                                    Start
                                </Col>
                                <Col sm={10}>
                                    <TimePicker
                                        step={15}
                                        onChange={e => {
                                            resource.time = e;
                                            this.setState(resource);
                                        }}
                                        value={resource.time}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </LeadsFilterModal>
                <Row>
                    <SplitPane split="vertical" defaultSize={"50%"} minSize={500}
                               style={{width: isMobile ? '100%' : null}}>
                        <div className={`left-panel`}>
                            {this.renderTotal()}
                            <Row className={!smMobile && "vertical-align"}>
                                <Col md={6} xs={12}
                                     className={smMobile ? 'no-left-padding top10 font10' : 'no-left-padding top10 font12'}>
                                    <ColorCheckbox value={resource.showTodaysRoute}
                                                   label='Show route'
                                                   onChange={() => {
                                                       this.setState({
                                                           resource: {
                                                               ...this.state.resource,
                                                               showTodaysRoute: !this.state.resource.showTodaysRoute
                                                           }
                                                       }, this.reload)
                                                   }}
                                    />
                                    <ColorCheckbox value={resource.showTodaysRouteAllUsers}
                                                   label='Show route for all users'
                                                   onChange={() => {
                                                       this.setState({
                                                           resource: {
                                                               ...this.state.resource,
                                                               showTodaysRouteAllUsers: !resource.showTodaysRouteAllUsers,
                                                               person_id: null
                                                           }
                                                       }, () => {
                                                           this.setState({events: []}, () => this.reload())
                                                       })
                                                   }}
                                    />
                                </Col>
                                <Col md={6} xs={12} className='hright no-right-padding'>
                                    <div className='pr-20'>
                                        <div className='font10 top10 no-left-padding'>
                                            <span className='mr-5'>Stops: {balance.events}</span>
                                            <span>Hrs: {balance.hours}</span>
                                        </div>
                                        <strong className={smMobile ? 'font10' : 'font11'}>Total:
                                            {" "}<Dollars amount={this.state.total}/>
                                        </strong>
                                    </div>
                                    <div className='pr-20'>
                                        <div className='font10 top10 no-left-padding'>
                                            <span className='mr-5'>Stops: {balance.phcStops}</span>
                                            <span>Hrs: {balance.phcHrs}</span>
                                        </div>
                                        <strong className={smMobile ? 'font10' : 'font11'}>PHC:
                                            {" "}<Dollars amount={this.state.phcTotal}/>
                                        </strong>
                                    </div>
                                    <div className='pr-20'>
                                        <div className='font10 top10 no-left-padding'>
                                            <span className='mr-5'>Stops: {balance.tcStops}</span>
                                            <span>Hrs: {balance.tcHrs}</span>
                                        </div>
                                        <strong className={smMobile ? 'font10' : 'font11'}>TC:
                                            {" "}<Dollars amount={this.state.tcTotal}/>
                                        </strong>
                                    </div>
                                    {this.state.resource.show_exclusive &&
                                        <div className='top10 no-left-padding'>
                                            <div className='font10 text-purple'>
                                                <span className='mr-5'>Stops: {balance.exclusiveStops}</span>
                                                <span>Hrs: {balance.exclusiveHrs}</span>
                                            </div>
                                            <strong
                                                className={smMobile ? 'font10 text-purple' : 'font11 text-purple'}>Exclusive:
                                                {" "}<Dollars amount={this.state.exclusiveTotal}/>
                                            </strong>
                                        </div>
                                    }
                                </Col>
                            </Row>

                            <Row className="no-margin">
                                <Col xs={12} className="no-left-padding no-right-padding">
                                    <GoogleMapLoader
                                        containerElement={
                                            <div
                                                id="mapSchedulerMap"
                                                className="remaining vertical-responsive-height"
                                            />
                                        }
                                        googleMapElement={
                                            <GoogleMapElement
                                                ref={it => this.refGoogleMap(it, sites.filter(site => site.latitude))}
                                                {...defaultMapParams(sites, null, null, client)}
                                            >
                                                {renderMarkers(proposal_services)}

                                            </GoogleMapElement>
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="scheduler-wrapper" id='#map-scheduler' style={{position: 'relative'}}>
                            {
                                calendarVisible &&
                                <FormGroup id='scheduler-date-picker'>
                                    <section className="datetime-picker--section">
                                        <Datetime open viewMode="days"
                                                  value={midDate}
                                                  onChange={e => {
                                                      this.updateDate(e)
                                                  }}
                                        />
                                    </section>
                                </FormGroup>
                            }
                            {this.state && start_date && <FullCalendar
                                titleFormat= {{
                                    day: "2-digit",
                                    month: 'long',
                                    year: 'numeric'
                                }}
                                dayHeaderFormat={{
                                    weekday: 'short',
                                    month: '2-digit',
                                    day: '2-digit',
                                    omitCommas: true
                                }}
                                eventClassNames={this.state.eventsLoading && "disabled pointer-events-disabled"}
                                ref={this.calendarComponentRef}
                                fixedWeekCount={false}
                                plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, bootstrapPlugin]}
                                timezone="local"
                                slotDuration="00:15:00"
                                scrollTime={start_time}
                                slotLabelInterval="01:00"
                                events={this.assignColorToEvents(calendarEvents)}
                                eventSources={this.assignColorToEvents(calendarEvents)}
                                droppable={true}
                                selectable={true}
                                editable={true}
                                height={765}
                                eventsSet={this.handleEvents}
                                eventClick={this.handleClick}
                                eventDrop={this.updateEvent}
                                eventResize={this.updateEvent}
                                initialDate={this.state.midDate.format()}
                                eventOrder={"start"}
                                dayMaxEventRows={true}
                                slotEventOverlap={false}
                                initialView={defaultView}
                                filterDate={filterDate}
                                views={{
                                    dayGrid: {
                                        dayMaxEventRows: 2
                                    },
                                    timeGrid: {
                                        dayMaxEventRows: 2
                                    }
                                }}
                                viewDidMount={(arg) => {
                                    this.setState({view: arg.view});
                                }}
                                customButtons={{
                                    prev: {
                                        click: () => {
                                            this.updateDate(moment(this.state.midDate).subtract(1, this.period[this.state.view.type]));
                                        }
                                    },
                                    next: {
                                        click: () => {
                                            this.updateDate(moment(this.state.midDate).add(1, this.period[this.state.view.type]));
                                        }
                                    },
                                    today: {
                                        text: 'today',
                                        click: () => {
                                            this.updateDate(moment())
                                        }
                                    },
                                    sync: {
                                        text: <span className="glyphicon glyphicon-refresh"/>,
                                        click: function () {
                                            if (client.gcal_authorized) {
                                                syncCalendar(client.id, reload);
                                            } else {
                                                reload();
                                            }
                                        }
                                    },
                                    calendar: {
                                        text: <span className="glyphicon glyphicon-calendar"/>,
                                        click: toggleCalendar
                                    }
                                }}
                                id="scheduler"
                                headerToolbar={{
                                    left: 'prev,next today calendar',
                                    center: 'title',
                                    right: 'sync,dayGridMonth,timeGridWeek,timeGridDay,list'
                                }}
                                dayCellDidMount={(arg) => {
                                    if (arg.view.type === 'timeGridWeek' || this.state.view?.type !== 'timeGridDay') {
                                        this.setState({view: arg.view});
                                    }
                                }}
                                eventContent={(e) => <EventContent key={e.event.extendedProps.id} event={e}
                                                                   employees={employees}
                                                                   proposalSites={proposalSites}/>
                                }
                                eventDidMount={(event) => {
                                    event.el.setAttribute('event_id', event.event.extendedProps.event_id)
                                }}
                                progressiveEventRendering={false}
                            />}
                        </div>
                    </SplitPane>
                </Row>
                <FilterModal show={this.state.showModal} onHide={this.closeFilterModal}
                             onSave={this.updateResourceAttributes}/>
                {showEventModal && <SchedulerEventDialog
                    handleClose={() => {
                        this.setState({showEventModal: false}, () => {
                            this.reload(true);
                        });
                    }}
                    event={this.state.event}
                />}
            </Grid>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        state: state,
        client: state.client.customerInfo,
        clientId: state.auth.client_id,
        baseUrl: state.auth.baseUrl,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    };
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(MapSchedulerPage));
